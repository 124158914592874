<template>
  <div class="item-box">
<!--    <div class="tips">2021年政策</div>-->
    <div class="content-box">
      <div class="left-box">
        <span>{{title}}</span>
        <div>{{subtitle}}</div>
      </div>
      <div class="right-box">
        <div class="btn-box">立即计算</div>
        <!-- <span>计算人次{{num}}人</span> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    num: {
      type: String,
    }
  }
}
</script>

<style scoped>
.item-box {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 12px 0 10px 0;
  margin-bottom: 12px;
}

.tips {
  width: 76px;
  height: 20px;
  background-image: url("../../../assets/imgs/calc/annuity-tips.png");
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 12px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -2px;
}

.content-box {
  padding: 8px 16px 0 16px;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.left-box div{
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #808080;
}

.left-box span{
  font-size: 18px;
  font-weight: 800;
  line-height: 22px;
  color: #333333;
}

.right-box {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.btn-box {
  width: 70px;
  padding: 4px 10px;
  background: #658CF1;
  border-radius: 15px;
  font-size: 12px;
  line-height: 22px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-box span {
  font-size: 10px;
  line-height: 22px;
  color: #658CF1;
}
</style>