<template>
  <div class="page">
    <calc-list-item title="个人帐户月入金额" subtitle="个人帐户月入金额计算" num="79"
                       @click.native="$router.push({ name: 'MedicalInsurancePage' })"></calc-list-item>
    <calc-list-item title="一次性趸缴" subtitle="未缴满年限，一次性趸缴计算" num="119"
                       @click.native="$router.push({ name: 'MedicalPayInFullPage'})"></calc-list-item>
  </div>
</template>

<script>
import CalcListItem from '@/views/calc/components/CalcListItem'
export default {
  components: {
    CalcListItem
  }
}
</script>

<style scoped>
.page {
  padding: 20px 24px ;
  background: #F9FCFF;
  min-height: 100vh;
}

</style>